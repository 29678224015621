import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { richTextRenderOptions } from "../helpers/constants";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import SearchSimple from "../components/organisms/search/SearchSimple";
import Image from "../components/atoms/image/Image";

const ItemLink = styled.a`
  display: inline-block;
  text-align: left;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
`;

const Block = styled.div`
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
`;

const BlockHeading = styled.h3`
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.02em;
`;

const BlockLabel = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  margin-bottom: 4px;
  color: #6f6f6f;
  margin-top: 1rem;
`;

const propTypes = {
  data: PropTypes.object.isRequired,
};

class DistibutorPageTemplate extends React.Component {
  render() {
    const layout = this.props.data.contentfulDistributor;
    const {
      title,
      email,
      description,
      distributor___offer,
      slug,
      website,
      phones,
      logo,
      metaDescription,
      openGraphImage,
    } = layout;

    const meta = [
      { name: "description", content: metaDescription?.metaDescription },
      { property: "og:description", content: metaDescription?.metaDescription },
      { property: "og:image", content: openGraphImage?.url },
    ];

    return (
      <Layout>
        <Seo meta={meta} title={title} description={metaDescription?.metaDescription} />
        <section className="container section--spacing">
          <div className="row">
            <div className="col-md-10">
              <h1 className="heading--spacing">{title}</h1>
            </div>
          </div>
        </section>
        <Divider />

        <div className="container section--spacing">
          <div className="row">
            <div className="col-md-8">
              {logo && (
                <Image
                  alt={logo.title}
                  fluid={logo.fluid}
                  svg={logo.svg}
                  file={logo.file}
                />
              )}

              {description ? (
                <div>{renderRichText(description, richTextRenderOptions)}</div>
              ) : null}
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-3">
              <Block>
                <BlockHeading>За контакти</BlockHeading>
                {website ? (
                  <div>
                    <BlockLabel>Сайт</BlockLabel>
                    <ItemLink
                      href={website}
                      className="list__link text-underline">
                      {website}
                    </ItemLink>
                  </div>
                ) : (
                  ""
                )}
                {phones && phones.length > 0 ? (
                  <div>
                    <BlockLabel>Телефон</BlockLabel>
                    {phones.map((phone, i) => (
                      <div key={i}>
                        <ItemLink
                          href={`tel:${phone}` || ""}
                          className="list__link text-underline">
                          {phone}
                        </ItemLink>
                        <br />
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {email ? (
                  <div>
                    <BlockLabel>Имейл</BlockLabel>
                    <ItemLink
                      href={`mailto:${email}`}
                      className="list__link text-underline">
                      {email}
                    </ItemLink>
                  </div>
                ) : (
                  ""
                )}
                <Divider />
              </Block>

              <Block>
                <BlockHeading>Оферти</BlockHeading>

                {distributor___offer ? (
                  distributor___offer
                    .filter(
                      (offer) =>
                        offer !== null &&
                        offer.details !== null &&
                        offer.details.type === null
                    )
                    .map((offer, i) => (
                      <div key={i}>
                        <ItemLink
                          href={"/distributors/" + slug + "/" + offer.slug}
                          className="list__link text-underline">
                          {offer.offerName}
                        </ItemLink>
                        <br />
                      </div>
                    ))
                ) : (
                  <div>Няма обявени оферти</div>
                )}
                <Divider />
              </Block>
            </div>
          </div>
        </div>
        <section className="background-gray section--spacing">
          <div className="container section--spacing">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <h2 className="heading--spacing text-center">
                  Сравнете оферти
                </h2>
                <SearchSimple />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

DistibutorPageTemplate.propTypes = propTypes;

export default DistibutorPageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    contentfulDistributor(id: { eq: $id }, node_locale: { eq: "bg" }) {
      id
      slug
      title
      website
      phones
      email
      description {
        raw
      }
      metaDescription {
        metaDescription
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      openGraphImage {
        url
      }
      distributor___offer {
        offerName
        details {
          type
        }
        slug
      }
      logo {
        svg {
          content
        }
        file {
          contentType
          url
          fileName
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
  }
`;
